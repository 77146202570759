@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@font-face {
  font-family: 'aalaojiezhaopaiti';
  src: url('/public/aalaojiezhaopaiti.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}